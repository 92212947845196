<template>
	<div class="view">
		<div class="main">
			<b-row class="logo-wrap">
				<b-col class="logo">
					<b-link src="/"><img src="../assets/logo.svg" v-on:click="$router.push('/')"/></b-link>
				</b-col>
			</b-row>
			<b-row align-h="center">
				<b-col class="form-wrap">
					<b-row>
						<b-col class="view-title">회원가입</b-col>
					</b-row>

					<b-form-group id="email" :state="email_empty || (email_validation && same_email_check)">
						<b-form-input class="input" type="email" v-model="email" placeholder="이메일" debounce="300" @input="resetSameEmail" />
						<b-form-invalid-feedback v-html="email_feedback" :state="email_empty || (email_validation && same_email_check)"></b-form-invalid-feedback>
					</b-form-group>

					<b-form-group id="password1" invalid-feedback="비밀번호는 영문과 숫자를 포함하여 10자 이상으로 입력해주세요." :state="password1_empty || password1_validation">
						<b-form-input class="input" type="password" v-model="password1" placeholder="비밀번호" debounce="300" />
					</b-form-group>

					<b-form-group id="password2" invalid-feedback="비밀번호가 일치하지 않습니다." :state="password2_empty || password2_validation">
						<b-form-input class="input" v-model="password2" placeholder="비밀번호 재입력" type="password" debounce="300" />
					</b-form-group>

					<b-form-group id="full_name" invalid-feedback="성명을 입력해주세요. (2글자 이상)" :state="full_name_empty || full_name_validation">
						<b-form-input class="input" v-model="full_name" placeholder="성함" debounce="300" />
					</b-form-group>

					<b-form-group id="phone" invalid-feedback="휴대전화 번호를 확인해주세요." :state="phone_empty || phone_validation">
						<b-form-input class="input" v-model="phone" placeholder="휴대전화 번호" debounce="300" />
					</b-form-group>

					<b-form-group id="birth_date" invalid-feedback="생년월일을 확인해주세요." :state="birth_date_empty || birth_date_validation">
						<b-row class="birth_date">
							<b-col>
								<b-form-select v-model="birth_year" :options="year_options"></b-form-select>
							</b-col>
							<b-col>
								<b-form-select v-model="birth_month" :options="month_options"></b-form-select>
							</b-col>
							<b-col>
								<b-form-select v-model="birth_day" :options="day_options"></b-form-select>
							</b-col>
						</b-row>
						<!-- <b-form-datepicker class="input no-padding" v-model="birth_date" placeholder="생년월일" debounce="300" label-no-date-selected="날짜를 선택해주세요" label-help="화살표를 클릭해서 연, 월을 이동할 수 있습니다" initial-date="1990-01-01"/> -->
					</b-form-group>

					<b-form-group id="school">
						<b-form-input id="school-name" class="input" v-model="school_name" placeholder="소속 학교 찾기" v-b-modal.findSchool @focus.once="focusSearch('findSchool')" />
					</b-form-group>

					<b-button v-if="!routerCheck && !form_submitted" type="submit" disabled> 다음 </b-button>
					<b-button class="orange" v-if="routerCheck && !form_submitted" type="submit" v-on:click="makeSignUp"> 다음 </b-button>
					<b-button v-if="form_submitted" disabled><b-spinner></b-spinner></b-button>
				</b-col>
			</b-row>
		</div>

		<b-modal size="lg" id="findSchool" title="학교 검색" hide-footer @hide="initializeModal">
			<b-row>
				<b-col cols="9">
					<b-form-input class="input" v-model="keyword" :state="keyword_validation" placeholder="학교 이름으로 검색해주세요." v-on:keyup.enter="makeSearch" />
				</b-col>
				<b-col cols="3">
					<b-button class="orange" type="submit" v-on:click="makeSearch"> 검색 </b-button>
				</b-col>
			</b-row>

			<b-table v-if="search_validation && !makeSchool" small hover :fields="fields" :items="school_list" primary-key="id" @row-clicked="rowSelect" />

			<b-container id="make-school" v-if="search_conducted">
				<b-row align-h="center" v-if="!makeSchool">
					<b-col style="text-align:center;">{{ search_feedback }}</b-col>
				</b-row>
				<b-row align-h="center" v-if="!makeSchool">
					<b-col style="text-align:center;">소속 학교가 검색에 안 나오나요?<br />주소를 입력하여 직접 등록할 수도 있습니다.</b-col>
				</b-row>
				<b-row class="bottom" align-h="center">
					<b-button v-if="!makeSchool" class="orange" v-on:click="makeSchool = true">직접 입력하기</b-button>
					<b-col v-else class="text-center view-title mb-3">학교 직접 입력하기</b-col>
				</b-row>
				<b-form-group>
					<b-form-input v-if="makeSchool" class="input" v-model="school_name" placeholder="학교명" />
				</b-form-group>
				<b-form-group>
					<b-form-input v-if="makeSchool" class="input" v-model="school_address" placeholder="학교주소" />
				</b-form-group>
				<b-form-group>
					<b-form-input v-if="makeSchool" class="input" v-model="zipcode" placeholder="우편번호" />
				</b-form-group>
				<b-row v-if="makeSchool && !post_school_validation" align-h="center">
					<b-col style="text-align:center;">
						학교 정보를 정확히 입력해주세요. <br />
						관리자가 확인 후 승인해드릴 예정입니다. <br />
						내용에 오류가 있을 경우 서비스 이용이 원활하지 않을 수 있습니다.
					</b-col>
				</b-row>
				<b-row class="bottom" align-h="center">
					<b-button v-if="makeSchool && !post_school_validation" disabled>입력 완료</b-button>
					<b-button class="orange" v-if="makeSchool && post_school_validation" v-on:click="closeSchoolModal">입력 완료</b-button>
				</b-row>
			</b-container>
		</b-modal>
		<Footer />
	</div>
</template>

<script>
/* eslint-disable */
import Footer from '@/components/Footer';

export default {
	name: 'NolauthSignUp',
	metaInfo: {
		// 페이지 제목 설정
		title: '회원가입',
		titleTemplate: '%s | NOLGONG SCHOOL',
		meta: [
			// SEO 설정
			{
				name: 'description',
				content:
					'학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소> | ‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'description',
			},
			{ name: 'keywords', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			{ name: 'author', content: '(주)놀공' },
			{ name: 'subject', content: '학교공간혁신 프로그램, 통일교육 <월페커즈 인스쿨>, 공간감수성교육 <건축놀이사무소>' },
			// SNS 설정
			{
				property: 'og:type',
				content: 'website',
				vmid: 'og:type',
			},
			{
				property: 'og:title',
				content: '회원가입',
				vmid: 'og:title',
			},
			{
				property: 'og:description',
				content: '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'og:description',
			},
			{
				property: 'og:url',
				content: 'https://nolgongschool.com/signup',
				vmid: 'og:url',
			},
			{
				property: 'og:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'og:image',
			},
			{
				property: 'og:image:alt',
				content: '"NOLGONG SCHOOL"',
				vmid: 'og:image:alt',
			},
			{
				property: 'og:image:width',
				content: '800',
				vmid: 'og:image:width',
			},
			{
				property: 'og:image:height',
				content: '400',
				vmid: 'og:image:height',
			},
			{
				property: 'og:locale',
				content: 'ko_KR',
				vmid: 'og:locale',
			},
			{
				property: 'og:site_name',
				content: 'NOLGONG SCHOOL',
				vmid: 'og:site_name',
			},
			{
				property: 'twitter:card',
				content: 'summary',
				vmid: 'twitter:card',
			},
			{
				property: 'twitter:site',
				content: '@nolgong',
				vmid: 'twitter:site',
			},
			{
				property: 'twitter:title',
				content: '회원가입 | NOLGONG SCHOOL',
				vmid: 'twitter:title',
			},
			{
				property: 'twitter:description',
				content: '‘놀공스쿨’은 초/중/고 선생님들과 다음 세대를 위한 미래교육으로의 여정을 함께 합니다. 놀공스쿨에 가입한 선생님은 누구나 디지털 통일교육 <월페커즈>를 비롯해 (주)놀공이 개발한 디지털 수업콘텐츠를 지원받아 활용하실 수 있습니다.',
				vmid: 'twitter:description',
			},
			{
				property: 'twitter:image',
				content: 'https://nolgongschool.com/static/social_image_horizontal.png',
				vmid: 'twitter:image',
			},
			{
				property: 'twitter:image:alt',
				content: '"NOLGONG SCHOOL" 문구, 건물과 깃발을 표현한 초록색 학교 모양 아이콘',
				vmid: 'twitter:image:alt',
			},
		],
	},
	data() {
		return {
			// 회원가입 1단계 데이터
			email: '',
			password1: '',
			password2: '',

			// 회원가입 2단계 데이터
			full_name: '',
			phone: '',
			// birth_date: '',
			birth_year: '',
			birth_month: '',
			birth_day: '',
			school_id: -1, // 서버 전송용

			// 학교 검색 관련
			keyword: '', // 팝업창 검색어
			school_list: [], // 팝업창 검색 결과용
			search_feedback: '', // 검색 결과 없을 시 피드백
			search_conducted: false,
			school_name: '', // 클라이언트 조회용
			makeSchool: false, // 학교 직접 입력 여부
			school_address: '', // 학교 직접 입력 시 주소
			zipcode: '',
			fields: [
				{ key: 'name', label: '학교명' },
				{ key: 'address', label: '주소' },
				{ key: 'zipcode', label: '우편번호' },
			],

			// email_feedback: '', // 이메일 경고 메세지 variation
			same_email_check: true, // 이메일이 유효한지 판단
			form_submitted: false,

			year_options: [{ text: '생년', value: '', disabled: true }].concat([...Array(121).keys()].map((n) => String(n + 1900).padStart(2, '0')).reverse()),
			month_options: [{ text: '월', value: '', disabled: true }].concat([...Array(12).keys()].map((n) => String(n + 1).padStart(2, '0'))),
			day_options: [{ text: '일', value: '', disabled: true }].concat([...Array(31).keys()].map((n) => String(n + 1).padStart(2, '0'))),
		};
	},
	computed: {
		birth_date() {
			return `${this.birth_year}-${this.birth_month.padStart(2, '0')}-${this.birth_day.padStart(2, '0')}`;
		},
		// 이메일 형식 검사 처리
		email_feedback() {
			if (this.same_email_check) return '이메일의 형태로 입력해주세요.';
			else return '중복되는 이메일이 있습니다.<br />비밀번호를 잊으셨나요? <a href="/password/reset">[비밀번호 찾기]</a>';
		},
		email_validation() {
			var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (re.test(this.email)) {
				return true;
			} else {
				// this.email_feedback = '이메일의 형태로 입력해주세요.'
				return false;
			}
		},
		// 비밀번호 영문, 숫자, 10글자 이상 검사
		password1_validation() {
			var reg = /^.*(?=.{10,64})(?=.*[0-9])(?=.*[a-zA-Z]).*$/;
			if (reg.test(this.password1)) return true;
			return false;
		},
		// 비밀번호 일치하는지 검사
		password2_validation() {
			return this.password2.length > 0 && this.password1 === this.password2;
		},
		email_empty() {
			return this.email.length === 0;
		},
		password1_empty() {
			return this.password1.length === 0;
		},
		password2_empty() {
			return this.password2.length === 0;
		},
		// 필수 입력 처리
		full_name_validation() {
			return this.full_name.length > 1;
		},
		full_name_empty() {
			return this.full_name.length === 0;
		},
		// 필수 입력 처리
		phone_validation() {
			const reg = /^[0-9\- ]{8,20}$/;
			return reg.test(this.phone);
			// return this.phone.length > 8
		},
		phone_empty() {
			return this.phone.length === 0;
		},
		birth_date_validation() {
			return this.birth_date.length > 1;
		},
		birth_date_empty() {
			return this.birth_date.length === 0;
		},
		school_validation() {
			return this.school_name.length > 0;
		},
		// 두 글자 이상 검색
		keyword_validation() {
			return this.keyword.length > 1;
		},
		// 검색 결과 있는지 없는지 확인
		search_validation() {
			return this.school_list.length > 0;
		},
		post_school_validation() {
			return this.school_name.length > 0 && this.school_address.length > 0 && this.zipcode.length > 0;
		},
		// validation 통과하지 않으면 버튼에서 makeSignIn 메소드 동작하지 않음
		routerCheck() {
			return this.email_validation && this.password1_validation && this.password2_validation && this.full_name_validation && this.birth_date_validation && this.phone_validation && this.school_validation;
		},
	},
	methods: {
		// 회원가입 요청
		makeSignUp() {
			if (window.confirm('이메일주소는 로그인 아이디로 사용되며 수정할 수 없습니다.\n정확히 입력했는지 다시 한 번 확인해주세요:\n\n' + this.email)) {
				this.form_submitted = true;
				// actions 메소드 호출
				this.$store
					.dispatch('SIGNUP', {
						email: this.email,
						password: this.password1,
						re_password: this.password2,
					})
					.then((res) => {
						// 이메일 중복으로 요청 실패
						if (res.status === 400 && res.data.email) {
							this.same_email_check = false;
							this.form_submitted = false;
							console.log('email_duplication');
						}
						// 서버 응답이 정상일 경우
						if (res.status === 201) {
							this.putProfile();
							console.log('putProfile end');
						}
					})
					.catch((error) => {});
			}
		},
		putProfile() {
			// actions 메소드 호출
			this.$store
				.dispatch('SIGNIN', {
					email: this.email,
					password: this.password1,
				})
				.then((res) => {
					if (this.makeSchool) {
						this.$store
							.dispatch('POST_SCHOOL', {
								school_name: this.school_name,
								school_address: this.school_address,
								zipcode: this.zipcode,
							})
							.then((res) => {
								if (res.status === 201) {
									this.school_id = res.data.id;
								}
								return res;
							})
							.then(() => {
								return this.$store.dispatch('PUT_PROFILE', {
									full_name: this.full_name,
									phone: this.phone,
									school: this.school_id,
									birth_date: this.birth_date,
								});
							})
							.then(() => {
								return this.$store.dispatch('REQUEST_WELCOME_EMAIL', {
									email: this.email,
								});
							})
							.then((res) => {
								// 서버 응답이 정상일 경우
								// if (res.status === 200) {
								//// this.$router.push("/");
								// }
								// if (res.status === 200) {
								// console.log("params = " + this.$route.params)
								// // 다른 앱을 타고 들어와서 가입한거면
								// // 놀공스쿨 가입 절차가 완료되면
								// // 앱 사용 동의 화면으로 이동
								// if (this.$route.params.app_id) {
								//     this.$router.push("/ConsentToUseApp", {
								//         app_id: this.$route.params.app_id,
								//     });
								// }
								//}
								// if (this.$route.params.app_id) {
								// 	this.$router.push({
								// 		name: 'ConsentToUseApp',
								// 		params: {app_id: this.$route.params.app_id}
								// 	})
								// }
							})
							.catch((err) => err);
					} else {
						this.$store
							.dispatch('PUT_PROFILE', {
								full_name: this.full_name,
								phone: this.phone,
								school: this.school_id,
								birth_date: this.birth_date,
							})
							.then(() => {
								return this.$store.dispatch('REQUEST_WELCOME_EMAIL', {
									email: this.email,
								});
							})
							.then((res) => {
								// 서버 응답이 정상일 경우
								// if (res.status === 200) {
								//// this.$router.push("/");
								// }

								console.log('params = ' + this.$route.params);

								// // if (this.$route.params.app_id) {
								// 	this.$router.push({
								// 		name: 'ConsentToUseApp',
								// 		params: {app_id: this.$route.params.app_id}
								// 	})
								// // }
							})
							.catch((err) => err);
					}
				});
		},
		resetSameEmail() {
			this.same_email_check = true;
		},
		// 학교 검색 요청
		makeSearch() {
			this.$store
				.dispatch('SEARCH_SCHOOL', {
					keyword: this.keyword,
				})
				.then((res) => {
					// 검색 결과 정상일 경우
					if (res.status === 200) {
						this.search_conducted = true;
						this.school_list = res.data.results;
						this.makeSchool = false;
						this.search_feedback = '';
						// 검색 결과가 없는 경우 (에러 아님)
						if (this.school_list.length === 0) {
							this.search_feedback = '찾으시는 학교가 없습니다.';
						}
					}

					// 토큰 만료로 인한 요청 실패
					if (res.status === 401) {
						this.$router.push('/');
						window.location.reload();
						alert('다시 회원가입 하십시오.');
					}
				});
		},
		// 학교 직접 입력
		postSchool() {
			this.$store
				.dispatch('POST_SCHOOL', {
					school_name: this.school_name,
					school_address: this.school_address,
					zipcode: this.zipcode,
				})
				.then((res) => {
					if (res.status === 201) {
						this.school_id = res.data.id;
					}
				});
		},
		closeSchoolModal() {
			this.$bvModal.hide('findSchool');
		},
		// 팝업창 검색 결과에서 학교 선택
		rowSelect(item, index) {
			this.school_id = item.id; // 서버 전송용
			this.school_name = item.name; // 클라이언트용
			this.$bvModal.hide('findSchool');
			this.initializeModal();
		},

		// 학교 검색 input 필드 focus되면 모달 띄우기 (focus & click 둘 다 됨)
		focusSearch(modal) {
			if (this.school_name === '') {
				this.$bvModal.show(modal);
			}
		},

		initializeModal() {
			this.search_conducted = false;
			this.keyword = '';
			this.school_list = [];
		},
	},

	mounted() {
		console.log(this.$route.params.app_id);
	},
	components: {
		Footer,
	},
};
</script>

<style scoped>
/* 로고 IE 대응 */
.logo-ie {
	display: auto;
	height: 277px;
	width: 277px;
}
.logo-wrap {
	display: none;
}
/* 모던 브라우저 */
@supports (display: none) {
	.logo {
		display: none;
		overflow: hidden;
		padding-top: 8vh;
		padding-bottom: 4vh;
	}
	.logo img {
		/*width: 50vw;*/
		max-width: 277px;
		height: auto;
	}
	.logo-ie {
		display: none;
	}
	.logo-wrap {
		display: flex;
	}
}

.view-title {
	margin-bottom: 0.5em;
}
.form-wrap {
	max-width: 440px;
	margin-top: 1.5em;
}
.input {
	margin: auto;
	margin-top: 0em;
	width: 85%;
	color: #000000;
	font-size: 0.9em;
	border-color: #000000;
	border-width: 1px;
	border-radius: 2mm;
	background-color: #ffffff;
}
.btn {
	margin-bottom: 1em;
	width: 85%;
	font-size: 1rem;
	font-weight: bold;
}
.font-menu {
	margin: 1.25em auto;
	font-size: 1em;
	color: #979797;
}
.font-menu :hover {
	text-decoration: #979797 underline;
}
.link {
	cursor: pointer;
}
.privacy {
	color: #979797;
}
.modal-body .input,
.modal-body .btn {
	font-size: 1em;
	width: 100%;
	/*height: 32px;*/
	border-radius: 4px;
}
.modal-body .container {
	-webkit-box-shadow: none;
	box-shadow: none;
}

.modal-body .table {
	margin-top: 1rem;
}
.modal-body .col-3,
.modal-body .col-9 {
	padding: 0 10px;
}
.table > * {
	border-top: none;
}

/* 생년월일 선택시 "오늘" 버튼 숨기기 */
/*.view >>> .b-calendar .b-calendar-nav .btn:nth-of-type(3) {
  display: none;
}
*/
.row.birth_date {
	margin: auto;
	width: 85%;
	/*padding: 0 12px;*/
}
.row.birth_date .col {
	padding: 0 2px;
}
.row.birth_date .col:first-of-type {
	padding: 0 2px 0 0;
}
.row.birth_date .col:last-of-type {
	padding: 0 0 0 2px;
}
.row.birth_date .col .custom-select {
	border: 1px solid black;
	border-radius: 2mm;
	font-size: 0.9em;
	/*color: #6c757d;*/
}
#make-school,
#make-school .row,
#make-school .col {
	padding-left: 0;
	padding-right: 0;
	margin: auto;
	min-width: 0;
}

/* 화면 높이 너무 짧으면 요소 간 margin 조정 */
@media (max-height: 792px) {
	.logo img {
		width: 200px;
		height: 200px;
	}
	.form-wrap {
		/*margin-top: 0.5em;*/
	}
	.font-menu {
		font-size: 0.9em;
		margin: 0.75em auto;
	}
	.view-title {
		margin-bottom: 4vh;
	}
	.input {
		height: 36px;
	}
	.btn {
		line-height: 1.5em;
	}
}
@media (max-height: 667px) {
	.btn {
		line-height: 1.25em;
	}
	.btn.green {
		margin-bottom: 0.5em;
	}
	.input,
	.b-form-datepicker {
		height: 30px;
	}
	.font-menu {
		font-size: 0.9em;
	}
}
</style>
